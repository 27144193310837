/**
 * Register resource for async load with require-bundle
 */
(function(){

    'use strict';

    
    // Bail if require bundle or server variables not loaded
    if ( ! RequireBundle || ! lobsteranywhereCustomizationsSettings ) return;
    
    var settings = lobsteranywhereCustomizationsSettings,
        ver = lobsteranywhereCustomizationsSettings.assetsVersion;

    if ( ! RequireBundle.hasBundle( 'sticky-states' ) ) { RequireBundle.register( 'sticky-states', [ settings.jsLibPath + 'sticky-states' + ver + '.js', settings.cssPath + 'sticky-states' + ver + '.css' ], '[data-sticky-states]', function(){ StickyStates.init( settings.stickyStates ); } ); }

})();
